import { useEffect, useState } from "react";
import { RequestState } from "../../constants/request-state";
import { OriginatingTransaction } from "../../types/amr-pipeline/models/OriginatingTransaction";
import { isRequestNone } from "../../utils";
import { dealsService } from "../../services/deals.service";

export function usePreviousTransaction(
    dealReferenceName: string,
    transactionReferenceName: string,
    disabled?: boolean
) {
    const [previousTransaction, setTransaction] = useState<OriginatingTransaction | null>(null);
    const [previousTransactionRequestState, setRequestState] = useState(RequestState.none);

    useEffect(() => {
        if ( // reset data set if deal is changed
            !disabled &&
            !isRequestNone(previousTransactionRequestState) &&
            dealReferenceName &&
            transactionReferenceName) {
            setTransaction(null);
            setRequestState(RequestState.none);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealReferenceName, transactionReferenceName, disabled])

    useEffect(() => {
        if (!isRequestNone(previousTransactionRequestState)) {
            return;
        }

        const doRequest = async () => {
            setRequestState(RequestState.request);
            try {
                const transaction: OriginatingTransaction =
                    await dealsService.getPreviousTransaction(dealReferenceName, transactionReferenceName);
                setTransaction(transaction);
                setRequestState(RequestState.success);
            } catch (e) {
                setRequestState(RequestState.failure);
            }
        };

        doRequest();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousTransactionRequestState]);


    return { previousTransaction, previousTransactionRequestState };
}